<template>
  <div class="about" :style="{ backgroundImage: `url(${BgImage})` }" style="background-size: auto;">
    <section>
      <div class="container main-container">
        <!--Section One -->
        <section class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-md-12 header-col">
                <h1 class="header-title">From Our Vineyards To Your Glass</h1>
                <p class="header-sub">A History of more than 156 years of wine making</p>
              </div>
            </div>
            <div class="spacing"></div>
            <div class="row">
              <div class="col-md-6 wow fadeInLeft">
                <img class="img-fluid" src="../assets/images/faustino-portrait.png">
              </div>
              <div class="col-md-6 wow fadeInRight">
                <div class="row">
                  <div class="col-12 name-col">
                    <div class="name">Grupo Faustino</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <p class="faustino-desc">Grupo Faustino is a prestigious family group founded in 1861, owning some of the most recognized and leading export brands. It is a fourth generation family business and an undisputed leader in Rioja DO Gran Reserva category. The Group has seven wineries located in key Spanish designations of origin though most of the winemaking activity is concentrated in the DOCa Rioja.</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 rectangle-col d-none d-md-none d-lg-block">
                    <img class="img-fluid" src="../assets/images/rectangle.png">
                  </div>
                  <div class="col-12 group-logo-col">
                    <img class="img-fluid" src="../assets/images/grupo_faustino.png">
                  </div>
                </div>
                <div class="d-none d-md-none d-lg-block">
                  <div class="row">
                    <div class="col-md-2">
                      <img class="img-fluid" src="../assets/images/wine_logos/new.logo.faustino.active.png">
                    </div>
                    <div class="col-md-2">
                      <img class="img-fluid" src="../assets/images/wine_logos/new.logo.campillo.active.png">
                    </div>
                    <div class="col-md-2">
                      <img class="img-fluid" src="../assets/images/wine_logos/new.logo.portia.active.png">
                    </div>
                    <div class="col-md-2">
                      <img class="img-fluid" src="../assets/images/wine_logos/new.logo.valcarlos.active.png">
                    </div>
                    <div class="col-md-2">
                      <img class="img-fluid" src="../assets/images/wine_logos/new.logo.vitoria.active.png">
                    </div>
                    <div class="col-md-2">
                      <img class="img-fluid" src="../assets/images/wine_logos/new.logo.leganza.active.png">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-md-block d-lg-none">
              <div class="spacing"></div>
              <div class="row">
                <div class="col-md-2 col-4 mobile-wine-logo">
                  <img class="img-fluid" src="../assets/images/wine_logos/new.logo.faustino.active.png">
                </div>
                <div class="col-md-2 col-4">
                  <img class="img-fluid" src="../assets/images/wine_logos/new.logo.campillo.active.png">
                </div>
                <div class="col-md-2 col-4">
                  <img class="img-fluid" src="../assets/images/wine_logos/new.logo.portia.active.png">
                </div>
                <div class="col-md-2 col-4">
                  <img class="img-fluid" src="../assets/images/wine_logos/new.logo.valcarlos.active.png">
                </div>
                <div class="col-md-2 col-4">
                  <img class="img-fluid" src="../assets/images/wine_logos/new.logo.vitoria.active.png">
                </div>
                <div class="col-md-2 col-4">
                  <img class="img-fluid" src="../assets/images/wine_logos/new.logo.leganza.active.png">
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="spacing"></div>
        <!-- Section Two -->
        <section class="row">
          <div class="col-12">
            <div class="container vision-mission-container">
              <div class="row vision-mission-row">
                <div class="col-md-6 vision-col wow fadeInDown">
                  <div class="card">
                    <div class="card-body">
                      <div class="logo">
                        <img class="img-fluid" src="../assets/images/vision_icon.png">
                      </div>
                      <div class="title">
                        Vision
                      </div>
                      <p>Become an international reference and recognized for making high quality wines.</p>
                      <p class="dot">&bull;</p>
                      <p>Our goal is to generate value satisfying, through a varied range and strong brands, the requirements of globalized market.</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mission-col wow fadeInDown">
                  <div class="card">
                    <div class="card-body">
                      <div class="logo">
                        <img class="img-fluid" src="../assets/images/vision_icon.png">
                      </div>
                      <div class="title">
                        Mission
                      </div>
                      <p>Care with the raw material, the vineyards, the land and the elaboration and ageing process.</p>
                      <p class="dot">&bull;</p>
                      <p>Ensure the sustainability of the ecosystem</p>
                      <p class="dot">&bull;</p>
                      <p>Behave co- responsibly in the creation of value</p>
                      <p class="dot">&bull;</p>
                      <p>Export vocation and contribution to the development of the value segment.</p>
                      <p class="dot">&bull;</p>
                      <p>Diversify the quality offer attending to the</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="spacing"></div>
        <div class="spacing"></div>
        <!-- Section Three -->
        <section class="row">
          <div class="col-12 wow fadeInDown">
            <div class="diamond-logo">
              <img class="img-fluid" src="../assets/images/diamond-logo.png">
            </div>
            <h1>Values</h1>
          </div>
          <div class="col-12 values-col">
            <div class="row">
              <div class="col-md-4 col-6 value wow fadeInDown" data-wow-delay="0.1s">
                <div class="logo">
                  <img class="img-fluid" src="../assets/images/values/employee.png">
                </div>
                <div class="number">231</div>
                <div class="description">Employees</div>
              </div>
              <div class="col-md-4 col-6 value wow fadeInDown" data-wow-delay="0.2s">
                <div class="logo">
                  <img class="img-fluid" src="../assets/images/values/barrels.png">
                </div>
                <div class="number">60,000</div>
                <div class="description">Barrels</div>
              </div>
              <div class="col-md-4 col-6 value wow fadeInDown" data-wow-delay="0.3s">
                <div class="logo">
                  <img class="img-fluid" src="../assets/images/values/bottles.png">
                </div>
                <div class="number">9 Million</div>
                <div class="description">Bottles</div>
              </div>
              <div class="col-md-4 col-6 value wow fadeInDown" data-wow-delay="0.4s">
                <div class="logo">
                  <img class="img-fluid" src="../assets/images/values/top.png">
                </div>
                <div class="number">Top 10</div>
                <div class="description">Winery Groups</div>
              </div>
              <div class="col-md-4 col-6 value wow fadeInDown" data-wow-delay="0.5s">
                <div class="logo">
                  <img class="img-fluid" src="../assets/images/values/hectare.png">
                </div>
                <div class="number">1,681</div>
                <div class="description">Hectares</div>
              </div>
              <div class="col-md-4 col-6 value wow fadeInDown" data-wow-delay="0.6s">
                <div class="logo">
                  <img class="img-fluid" src="../assets/images/values/wineries.png">
                </div>
                <div class="number">7</div>
                <div class="description">Wineries</div>
              </div>
              <div class="col-md-4 col-6 value wow fadeInDown" data-wow-delay="0.7s">
                <div class="logo">
                  <img class="img-fluid" src="../assets/images/values/appellations.png">
                </div>
                <div class="number">5</div>
                <div class="description">Appellations</div>
              </div>
              <div class="col-md-4 col-6 value wow fadeInDown" data-wow-delay="0.8s">
                <div class="logo">
                  <img class="img-fluid" src="../assets/images/values/turnovers.png">
                </div>
                <div class="number">50%</div>
                <div class="description">Export Turnovers</div>
              </div>
              <div class="col-md-4 col-6 value wow fadeInDown" data-wow-delay="0.9s">
                <div class="logo">
                  <img class="img-fluid" src="../assets/images/values/countries.png">
                </div>
                <div class="number">+120</div>
                <div class="description">Countries</div>
              </div>
            </div>
          </div>
        </section>
        <div class="spacing"></div>
      </div>
    </section>
    <section :style="{ backgroundImage: `url(${BgImage2})` }" style="background-size: cover; background-position: center">
      <div class="container">
        <div class="row">
          <div class="col-12 wow fadeInDown">
            <img class="img-fluid history-title-img" src="../assets/images/history-title.png">
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 wow fadeInLeft">
            <div class="row">
              <div class="col-12 history-thumbnail-col">
                <img class="img-fluid history-thumbnail-img" src="../assets/images/history-thumbnail.png">
              </div>
              <div class="col-12 history-thumbnail-desc-col">
                <p>Grupo Faustino’s history is the history of a family. A family that has lived by and for vineyards, cellars and wine. A family that had respected its values from one generation to the next and has also managed to adapt to the passage of time with constant innovations..</p>
                <p>From Eleuterio Martínez Arzok to the fourth generation which currently leads the winery more than 150 years of tradition and expertise in the world of wine have passed.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 history-timeline-right-col">
            <!--1861 -->
            <div class="row wow fadeInRight">
              <div class="col-md-6 col-6 justify-content-center align-self-center">
                <div class="year">1861</div>
                <div class="desc">Group's foundation by Eleuterio Martinez Arzok</div>
              </div>
              <div class="col-md-6 col-6">
                <img class="img-fluid" src="../assets/images/timelines/timeline-1861-founder.png">
              </div>
            </div>
            <div class="spacing"></div>
            <!-- 1913 -->
            <div class="row wow fadeInLeft">
              <div class="col-md-6 col-6">
                <img class="img-fluid" src="../assets/images/timelines/timeline-1930-bottledWime.png">
              </div>
              <div class="col-md-6 col-6 justify-content-center align-self-center">
                <div class="year">1913</div>
                <div class="desc">Faustino Martínez Pérez began marketing bottled wine business.</div>
              </div>
            </div>
            <div class="spacing"></div>
            <!-- 1957 -->
            <div class="row wow fadeInRight">
              <div class="col-md-6 col-6 justify-content-center align-self-center">
                <div class="year">1957</div>
                <div class="desc">In July, Faustino Martínez exports wine for the first time, to Austria.</div>
              </div>
              <div class="col-md-6 col-6">
                <img class="img-fluid" src="../assets/images/timelines/timeline-1957-exportAustria.png">
              </div>
            </div>
          </div>
        </div>
        <div class="spacing"></div>
        <div class="row">
          <div class="col-md-12 history-timeline-bottom-col">
            <!-- 1960 -->
            <div class="row wow fadeInLeft">
              <div class="col-md-6 col-6">
                <img class="img-fluid" src="../assets/images/timelines/timeline-1960-faustinoBorn.png">
              </div>
              <div class="col-md-6 col-6 justify-content-center align-self-center">
                <div class="year">1960</div>
                <div class="desc">The Faustino brand is born and quickly becomes one of the most prestigious brands.</div>
              </div>
            </div>
            <div class="spacing"></div>
            <!-- 1970 -->
            <div class="row wow fadeInRight">
              <div class="col-md-6 col-6 justify-content-center align-self-center text-right">
                <div class="year">1970</div>
                <div class="desc">The Group´s future projects with the purchase of vineyards in different designations of origin.</div>
              </div>
              <div class="col-md-6 col-6">
                <img class="img-fluid" src="../assets/images/timelines/timeline-1970-vineyardPlan.png">
              </div>
            </div>
            <div class="spacing"></div>
            <!-- 1990 -->
            <div class="row wow fadeInLeft">
              <div class="col-md-6 col-6">
                <img class="img-fluid" src="../assets/images/timelines/timeline-1990-campillo.png">
              </div>
              <div class="col-md-6 col-6 justify-content-center align-self-center">
                <div class="year">1990</div>
                <div class="desc">Bodegas Campillo inauguration</div>
              </div>
            </div>
            <div class="spacing"></div>
            <!-- 1995 -->
            <div class="row wow fadeInRight">
              <div class="col-md-6 col-6 justify-content-center align-self-center text-right">
                <div class="year">1995</div>
                <div class="desc">Bodegas Marqués de Vitoria inauguration</div>
              </div>
              <div class="col-md-6 col-6">
                <img class="img-fluid" src="../assets/images/timelines/timeline-1995-vitoria.png">
              </div>
            </div>
            <div class="spacing"></div>
            <!-- 1999 -->
            <div class="row wow fadeInLeft">
              <div class="col-md-6 col-6">
                <img class="img-fluid" src="../assets/images/timelines/timeline-1999leganza.png">
              </div>
              <div class="col-md-6 col-6 justify-content-center align-self-center">
                <div class="year">1999</div>
                <div class="desc">Finca Los Trenzones and Bodegas Leganza inauguration</div>
              </div>
            </div>
            <div class="spacing"></div>
            <!-- 2001 -->
            <div class="row wow fadeInRight">
              <div class="col-md-6 col-6 justify-content-center align-self-center text-right">
                <div class="year">2001</div>
                <div class="desc">Bodegas Valcarlos inauguration</div>
              </div>
              <div class="col-md-6 col-6">
                <img class="img-fluid" src="../assets/images/timelines/timeline-2001valcarlos.png">
              </div>
            </div>
            <div class="spacing"></div>
            <!-- 2010 -->
            <div class="row wow fadeInLeft">
              <div class="col-md-6 col-6">
                <img class="img-fluid" src="../assets/images/timelines/timeline-2010portia.png">
              </div>
              <div class="col-md-6 col-6 justify-content-center align-self-center">
                <div class="year">2010</div>
                <div class="desc">Bodegas Portia inauguration</div>
              </div>
            </div>
            <div class="spacing"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  import BgImage from '../assets/images/bg-about.png';
  import BgImage2 from '../assets/images/bg-about-2.png';
  import { WOW } from 'wowjs';
  export default {
    name: 'about',
    components: {

    },
    data () {
      return {
        BgImage,
        BgImage2
      }
    },
    mounted() {
      const wow = new WOW();

      wow.init();
    }
  }
</script>
